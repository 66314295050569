import React from 'react'
import { MobileAppProtectionLP } from '@page-cores'

const LandingPage = ({ location }) => {
  return (
    <MobileAppProtectionLP
      location={location}
      name="for/mobile-app-protection/promon"
      title="Are You Evaluating Promon App Shielding?"
    />
  )
}

export default LandingPage
